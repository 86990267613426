// Animator.jsx
import React from 'react';
import PropTypes from 'prop-types';

class Animator extends React.PureComponent {
  state = {
    now: new Date(),
  };

  componentDidMount() {
    this.startLoop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.fps !== prevProps.fps) {
      this.fpsInterval = 1000 / this.props.fps;
    }
  }

  componentWillUnmount() {
    this.stopLoop();
  }

  loop = () => {
    this._frameId = window.requestAnimationFrame(this.loop);

    const now = Date.now();
    const elapsed = now - this.then;

    if (elapsed > this.fpsInterval) {
      this.then = now - (elapsed % this.fpsInterval);

      this.setState({ now: new Date() });
    }
  };

  startLoop = () => {
    if (!this._frameId) {
      this.fpsInterval = 1000 / this.props.fps;
      this.then = Date.now();
      this.startTime = this.then;
      this._frameId = window.requestAnimationFrame(this.loop);
    }
  };

  stopLoop = () => {
    window.cancelAnimationFrame(this._frameId);
    this._frameId = undefined;
  };

  render() {
    const { children, render, ...props } = this.props;
    const { now } = this.state;

    if (render) {
      return render(now);
    } else {
      const child = React.Children.only(children);
      return React.cloneElement(child, {
        ...props,
        now,
        stopAnimator: this.stopLoop,
        startAnimator: this.startLoop,
      });
    }
  }
}

Animator.propTypes = {
  fps: PropTypes.number,
  children: PropTypes.node,
  render: PropTypes.func,
};

Animator.defaultProps = {
  fps: 5,
  children: null,
  render: null,
};

export default Animator;
