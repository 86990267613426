// getLiveStreamData.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';
import getOperationData from '../selector/getOperationData.js';
import getGoalData from '../selector/getGoalData.js';
import getMeData from '../selector/getMeData.js';
import {
  ExclusivePrivateStatus,
  GroupPrivateStatus,
} from '../resource/liveStreamConstants.js';

const defaultSelectPath = [];
const defaultDataKey = '';

/**
 * Select live stream data by live stream
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} [selectPath = []] - select path. default is [].
 * @param {string} [dataKey = ''] - data key. default is ''.
 * @return {any} The selected live stream data.
 */
const getLiveStreamData = createCachedSelector(
  (state, selectPath = defaultSelectPath) =>
    state.getIn(['liveStream', ...selectPath]),
  (state, selectPath, dataKey) => dataKey,
  (liveStream, dataKey = defaultDataKey) => {
    let result = liveStream;
    if (dataKey) {
      result = liveStream?.get(dataKey);
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)(
  (state, selectPath = defaultSelectPath, dataKey = defaultDataKey) =>
    `${selectPath.join(':')}:${dataKey}`
);

/**
 * get groups private trigger is accepted by stream id.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} streamId - stream id.
 * @return {boolean}  groups private trigger is accepted or not.
 */
export const getIsGroupPrivateAccepted = createCachedSelector(
  (state, streamId) =>
    getLiveStreamData(state, [streamId, 'groupPrivate'], 'status'),
  (state, streamId) =>
    getOperationData(
      state,
      ['liveStream', 'paid', streamId],
      'isPrivateStreamUnlocked'
    ),
  (groupPrivateStatus, isPrivateStreamUnlocked) => {
    return (
      groupPrivateStatus === GroupPrivateStatus.ACCEPTED &&
      isPrivateStreamUnlocked
    );
  }
)((state, streamId) => streamId);

/**
 * check if group private is triggered by other users.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} streamId - stream id.
 * @return {boolean} true/false group private is triggered by other users.
 */
export const getIsGroupPrivateTriggeredByOthers = createCachedSelector(
  (state, streamId) =>
    getLiveStreamData(state, [streamId, 'groupPrivate'], 'status'),
  (state, streamId) =>
    getOperationData(
      state,
      ['liveStream', 'paid', streamId],
      'isPrivateStreamUnlocked'
    ),
  (groupPrivateStatus, isPrivateStreamUnlocked) => {
    return (
      groupPrivateStatus === GroupPrivateStatus.CONSIDERING &&
      !isPrivateStreamUnlocked
    );
  }
)((state, streamId) => streamId);

/**
 * check if group private is triggered by myself.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} streamId - stream id.
 * @return {boolean} true/false group private is triggered by myself.
 */
export const getIsGroupPrivateTriggeredByMyself = createCachedSelector(
  (state, streamId) =>
    getLiveStreamData(state, [streamId, 'groupPrivate'], 'status'),
  (state, streamId) =>
    getOperationData(
      state,
      ['liveStream', 'paid', streamId],
      'isPrivateStreamUnlocked'
    ),
  (groupPrivateStatus, isPrivateStreamUnlocked) => {
    return (
      groupPrivateStatus === GroupPrivateStatus.CONSIDERING &&
      isPrivateStreamUnlocked
    );
  }
)((state, streamId) => streamId);

/**
 * check if a user (me) is in the exclusive private mode.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} streamId - stream id.
 * @return {boolean} true/false a user is in the exclusive private mode.
 */
export const getIsMeExclusivePrivateBuyer = createCachedSelector(
  state => getMeData(state, 'id'),
  (state, streamId) =>
    getLiveStreamData(state, [streamId, 'exclusivePrivate'], 'status'),
  (state, streamId) => {
    const currentGoalId = getLiveStreamData(
      state,
      [streamId, 'exclusivePrivate'],
      'currentId'
    );
    return getGoalData(state, [currentGoalId, 'context'], 'exclusive_to');
  },
  (meId, exclusivePrivateStatus, exclusiveToUserId) => {
    const isGoalCreator = meId != null && meId === exclusiveToUserId;
    return (
      exclusivePrivateStatus === ExclusivePrivateStatus.PERFORMING &&
      isGoalCreator
    );
  }
)((state, streamId) => `${streamId}`);

export default getLiveStreamData;
