// LiveStreamBadge.js
import { connect } from 'react-redux';

import LiveStreamBadge from '../component/LiveStreamBadge.jsx';
import getTimestampOffset, {
  Accuracy,
} from '../selector/getTimestampOffset.js';
import getLiveStreamData from '../selector/getLiveStreamData.js';
import getGoalData from '../selector/getGoalData.js';
import getPrivateShowStatus from '../selector/getPrivateShowStatus.js';

const mapStateToProps = (state, { streamId }) => {
  const privateShowStatus = getPrivateShowStatus(state, streamId);
  const timestampOffsetSeconds = getTimestampOffset(state, Accuracy.SECOND);
  const fundingId = getLiveStreamData(
    state,
    [streamId, 'privateShow'],
    'fundingId'
  );
  const fundingExp = getGoalData(state, [fundingId], 'exp');
  const streamStatus = getLiveStreamData(state, [streamId], 'status');
  const exclusivePrivateStatus = getLiveStreamData(
    state,
    [streamId, 'exclusivePrivate'],
    'status'
  );
  return {
    privateShowStatus,
    exclusivePrivateStatus,
    streamStatus,
    timestampOffsetSeconds,
    fundingExp,
  };
};

export default connect(
  mapStateToProps,
  null,
  // equals to `{ ...ownProps, ...stateProps, ...dispatchProps }`
  // ref: https://github.com/reduxjs/react-redux/blob/v7.1.0/src/connect/mergeProps.js
  null,
  { forwardRef: true } // Required by WithIntersectionObserver
)(LiveStreamBadge);
